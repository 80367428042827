import React from 'react';
import './style.css';
import profilePic from './saenoooos.svg'

function Home() {
    React.useEffect(() => {       window.scrollTo(0, 0);     }, []);
    return (


        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <section id="projects" className="content-section"><br/>
                <a href="#ai"><h2> &gt;&gt; AI Tool Quick Search &lt;&lt;</h2></a>
                <h2 id="projectsHeader">Projects</h2>

                <div className="projects-container">
                    <a className="project" href="#blockmaster">
                        <img src="./projects/blockmaster/banner.gif" alt="AI in Games"/>

                        <h3>Introducing AI to Minecraft</h3>
                        <p>Artificially intelligent chatbots are booming right now - and Minecraft is the best-selling
                            video game of all time! My most recent project aims to design and develop a multimodal
                            assistant that can be interacted within a Minecraft server. For more details click
                            here to be linked to the main site for this project.
                        </p>
                    </a>
                    <div className="project" id="projectFeatured">
                        <img src="./projects/railsafe/banner.gif" alt="Deep-Learning-Railways"/>

                        <h3>A Deep Learning Approach to Railway Accessibility</h3>
                        <p>My award-winning undergraduate final-year project. I built an AI-based predictive model
                            inferencer to detect platform edges at railway stations and display the information to users
                            in an audio-based format.</p>
                    </div>
                    <div className="project">
                        <img src="./projects/snowsniper/banner.gif" alt="BlockMasterAI"/>
                        <h3>How far can AI image generators go?</h3>
                        <p>The rise of generative AI poses a significant threat to independent artists. I built a simple
                            platformer game in Unity with a twist - every sprite is AI generated. I used this as a
                            proof-of-concept to investigate the real-world potential and consequences of generative AI
                            in the arts industry.</p>
                    </div>

                    <a className="project" href="#8queens">
                        <img src="./projects/8queens/banner.jpg" alt="8-Queen Minecraft"/>
                        <h3>Minecraft 8-Queen Solver</h3>
                        <p>Utilising Minecraft mechanics to solve complex mathematical search-related problems.</p>
                    </a>

                    <div className="project">
                        <img src="./railmapper.png" alt="Railmapper Icon"/>
                        <h3>Railmapper</h3>
                        <p>Railmapper was a basic railway display application I created for my A-Level Computer Science
                            Coursework in 2020.</p>
                    </div>
                    <div className="project">
                        <img src="saenoooos.svg" alt="More coming!"/>
                        <h3>Awaiting Arrival...</h3>
                        <p>I'll add some more cool things I've created over the new few months, stay tuned!</p>
                    </div>
                </div>
            </section>


        </div>
    );
}

export default Home;