import React from 'react';
import './style.css';
import profilePic from './saenoooos.svg'
function Home() {
    return (

	
        <div>
			<meta name="viewport" content="width=device-width, initial-scale=1" />
            <header id="hero">
                <div className="hero-container">
					<div className="profile-wrapper">
					<img src={profilePic} className="profile-img"/>
					<div className="profile-text-wrapper">
                    <h1>Hi there! I'm Saen.</h1>
					<img src={profilePic} className="mobile-profile-img"/>
					<h2>I'm a computer scientist specialized in artificial intelligence development. I build AI-based solutions, game plugins, and interactive websites.</h2>
					</div>
					</div>
					<h3>About Me</h3>

                <p>
                    I am a big data enthusiast and developer with a keen interest in the intersection of
                    human-computer interaction and data-driven design. During my time at university I
                    researched human-centered design optimization where I developed skills analysing and
                    improving how people engage with technology. I love transforming data into meaningful
                    insights and love playing around with statistics to create cool looking charts and graphs!
                </p>

                <p>

                    I also possess an extensive skillset covering machine learning, full-stack web development
                    and game development. This includes building chatbots, image prediction models, creating
                    immersive games and developing custom plugins and projects within games to showcase my
                    versatility and creative problem-solving skills. I thrive on digging into innovative ways to
                    merge technology and design in an attempt to solve impactful problems. As of 2024 I hold a
                    first class honours degree in Computer Science & AI from the University of London (Brunel).

                </p>

                <p>

                    I have also been a Minecraft modder and scripter for over 11 years, stress-testing and pushing
                    the game to its limits. I have developed a vision to challenge the boundaries of mathematical
                    reasoning within high-level simulated environments.
                    Not all of my projects are Minecraft-related but whenever  I do make something worthy of
                    showing off, it will be in the list below! Feel free to have a look at some of the things I've
                    done most recently...
                </p>
                </div>
            </header>
			</div>

    );
}

export default Home;